<template>
  <div>
    <div class="d-block">
      <div
        class="
          text-xs-h5 text-sm-h5 text-md-h4 text-lg-4 text-xl-4
          success--text
          d-inline
        "
      >
        <v-btn
          color="success"
          text
          icon
          class="mr-3"
          @click="RedirictGroups"
          large
        >
          <v-icon>mdi-arrow-left</v-icon> </v-btn
        >Подробности о {{ group.number }} группе
      </div>
    </div>
    <div>
      <v-tabs
        v-if="
          $store.getters.profile.user_access_level == 'training_officer' ||
          $store.getters.profile.id == group.teacher.user.id
        "
        v-model="tab"
        color="success"
      >
        <v-tab href="#details">Подробности</v-tab>
        <v-tab href="#attendance">Посещаемость</v-tab>
        <v-spacer></v-spacer>
        <v-btn
          @click="view_type = !view_type"
          v-if="tab == 'attendance'"
          large
          icon
          ><v-icon v-if="view_type">mdi-table</v-icon>
          <v-icon v-else>mdi-card-text</v-icon></v-btn
        >
      </v-tabs>
      <div v-else>
        <v-container fluid>
          <v-row>
            <v-col sm="12" md="8" lg="9" xl="9" cols="12">
              <div class="text-h5">Ученики</div>
              <v-data-table
                :headers="headers"
                :items="group.students"
                sort-by="name"
                :loading="x_loading"
                disable-pagination
                hide-default-footer
                dense
              >
                <template v-slot:item.name="{ item }">
                  <v-list-item>
                    <v-list-item-avatar size="32">
                      <v-img :src="api + item.user.image"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.user.first_name }} {{ item.user.last_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.user.user_access_level }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item.phone="{ item }">
                  <a
                    :href="'tel:+' + item.user.phone"
                    class="text-decoration-none black--text"
                    >+{{ item.user.phone }}
                  </a>
                </template>
                <template v-slot:item.email="{ item }">
                  <a
                    :href="'mailto:' + item.user.email"
                    class="text-decoration-none black--text"
                    >{{ item.user.email }}
                  </a>
                </template>
              </v-data-table>
            </v-col>
            <v-col sm="12" md="4" lg="3" xl="3" cols="12">
              <div class="text-h5">Учитель</div>
              <v-card outlined class="mb-5">
                <div class="d-flex text-center">
                  <v-scroll-y-transition mode="out-in">
                    <v-card v-if="group.teacher" class="pt-6 mx-auto" flat max-width="400">
                      <v-card-text>
                        <v-avatar size="120">
                          <v-img :src="api + group.teacher.user.image"></v-img>
                        </v-avatar>
                        <h3 class="text-h5 mb-2">
                          {{ group.teacher.user.first_name }}
                          {{ group.teacher.user.last_name }}
                        </h3>
                        <div class="blue--text mb-2">
                          <a
                            style="text-decoration: none"
                            :href="'mailto:' + group.teacher.user.email"
                            >{{ group.teacher.user.email }}</a
                          >
                        </div>
                        <div class="blue--text subheading font-weight-bold">
                          <a
                            style="text-decoration: none"
                            :href="'tel:+' + group.teacher.user.phone"
                            >+{{ group.teacher.user.phone }}</a
                          >
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-text>
                        Учебный план:
                        <v-btn
                          :to="{
                            name: 'Syllabus',
                            params: { id: group.teacher.id },
                          }"
                          small
                          color="success"
                          >Открыть</v-btn
                        >
                      </v-card-text>
                    </v-card>
                  </v-scroll-y-transition>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item value="details">
          <v-container fluid>
            <v-row>
              <v-col sm="12" md="8" lg="9" xl="9" cols="12">
                <div class="text-h5">Ученики</div>
                <v-data-table
                  :headers="headers"
                  :items="group.students"
                  sort-by="name"
                  :loading="x_loading"
                  disable-pagination
                  hide-default-footer
                  dense
                >
                  <template v-slot:item.name="{ item }">
                    <v-list-item>
                      <v-list-item-avatar size="32">
                        <v-img :src="api + item.user.image"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.user.first_name }} {{ item.user.last_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.user.user_access_level }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item.phone="{ item }">
                    <a
                      :href="'tel:+' + item.user.phone"
                      class="text-decoration-none black--text"
                      >+{{ item.user.phone }}
                    </a>
                  </template>
                  <template v-slot:item.email="{ item }">
                    <a
                      :href="'mailto:' + item.user.email"
                      class="text-decoration-none black--text"
                      >{{ item.user.email }}
                    </a>
                  </template>
                </v-data-table>
              </v-col>
              <v-col sm="12" md="4" lg="3" xl="3" cols="12">
                <div class="text-h5">Учитель</div>
                <v-card outlined class="mb-5">
                  <div class="d-flex text-center">
                    <v-scroll-y-transition mode="out-in">
                      <v-card v-if="group.teacher" class="pt-6 mx-auto" flat max-width="400">
                        <v-card-text>
                          <v-avatar size="120">
                            <v-img
                              :src="api + group.teacher.user.image"
                            ></v-img>
                          </v-avatar>
                          <h3 class="text-h5 mb-2">
                            {{ group.teacher.user.first_name }}
                            {{ group.teacher.user.last_name }}
                          </h3>
                          <div class="blue--text mb-2">
                            <a
                              style="text-decoration: none"
                              :href="'mailto:' + group.teacher.user.email"
                              >{{ group.teacher.user.email }}</a
                            >
                          </div>
                          <div class="blue--text subheading font-weight-bold">
                            <a
                              style="text-decoration: none"
                              :href="'tel:+' + group.teacher.user.phone"
                              >+{{ group.teacher.user.phone }}</a
                            >
                          </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                          Учебный план:
                          <v-btn
                            :to="{
                              name: 'Syllabus',
                              params: { id: group.teacher.id },
                            }"
                            small
                            color="success"
                            >Открыть</v-btn
                          >
                        </v-card-text>
                      </v-card>
                    </v-scroll-y-transition>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="attendance">
          <div v-if="view_type">
            <v-container fluid>
              <v-row class="mt-2">
                <v-col
                  cols="6"
                  xs="6"
                  sm="6"
                  md="4"
                  lg="2"
                  xl="2"
                  v-for="(lesson, index) in lessons"
                  v-bind:key="lesson.id"
                >
                  <v-card
                    @click="openDialog(lesson)"
                    elevation="3"
                    rounded="lg"
                    :class="isDone(lesson) ? 'white--text' : ''"
                    :color="DayColor(lesson)"
                  >
                    <v-card-title>
                      {{ index + 1 }}
                    </v-card-title>
                    <v-card-subtitle
                      :class="isDone(lesson) ? 'white--text' : ''"
                    >
                      {{ lesson.start_time | time_to_day }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <table v-else class="table">
            <thead>
              <th>ФИО</th>
              <th
                class="rotate"
                v-for="lesson in lessons"
                v-bind:key="lesson.id"
              >
                {{ lesson.start_time | time_to_day }}
              </th>
            </thead>
            <tbody>
              <tr v-for="student in group.students" v-bind:key="student.id">
                <td class="name">
                  {{ student.user.first_name }} {{ student.user.last_name }}
                </td>
                <td v-for="lesson in lessons" v-bind:key="lesson.id">
                  <div v-if="isDone(lesson)" class="align-center">
                    <v-icon v-if="is_absent(lesson, student)" color="red"
                      >mdi-minus</v-icon
                    >
                    <v-icon v-else color="green">mdi-check</v-icon>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <v-dialog max-width="480px" v-model="attendance_dialog">
            <v-card v-if="selected_attendance">
              <v-card-title>
                Посещаемость
                {{ selected_attendance[0].lesson.start_time | time_to_day }}
                <v-spacer></v-spacer>
                <v-icon @click="attendance_dialog = false">mdi-close</v-icon>
              </v-card-title>
              <v-card-text
                v-for="attendance in selected_attendance"
                :key="attendance.id"
              >
                {{ attendance.student.user.first_name }}
                {{ attendance.student.user.last_name }}
                <span v-if="attendance.status == 'came'" class="float-right"
                  ><v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else class="float-right"
                  ><v-icon color="red">mdi-minus</v-icon></span
                >
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog max-width="480px" v-model="attendance_form_dialog">
            <v-card v-if="selected_lesson">
              <v-card-title>
                Посещаемость
                {{ selected_lesson.start_time | time_to_day }}
                <v-spacer></v-spacer>
                <v-icon @click="attendance_form_dialog = false"
                  >mdi-close</v-icon
                >
              </v-card-title>
              <v-card-text v-for="student in students" :key="student.id">
                {{ student.first_name }}
                {{ student.last_name }}
                <v-btn
                  v-if="!student.came"
                  @click="student.came = true"
                  class="float-right"
                  icon
                  ><v-icon color="red">mdi-minus</v-icon></v-btn
                >
                <v-btn
                  v-else
                  @click="student.came = !student.came"
                  class="float-right"
                  icon
                  ><v-icon color="green">mdi-check</v-icon></v-btn
                >
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="SaveAttendance" class="white--text" color="green"
                  >Сохранить</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-snackbar v-model="snackbar" timeout="3000" color="warning" multi-line>
      <v-row align-content="center" align="center">
        <v-col cols="1">
          <v-icon color="white" left>mdi-alert-circle</v-icon>
        </v-col>
        <v-col cols="10">
          <span class="text-sm-caption text-md-body-2 text-lg-body-1 text-xl-h6">
            {{ snackbar_text }}
          </span>
        </v-col>
        <v-col cols="1">
          <v-btn
            :x-small="$vuetify.breakpoint.xs"
            :small="$vuetify.breakpoint.sm"
            icon
          >
            <v-icon color="white" @click="snackbar = false">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import { GROUP_GET } from "@/store/actions/group";
import { ATTENDANCE_GET, ATTENDANCE_POST } from "@/store/actions/attendance";
import { LESSONS_GET } from "@/store/actions/other";
import moment from "moment";
// import { jsPDF } from "jspdf";
export default {
  data() {
    return {
      group: {},
      tab: null,
      snackbar: false,
      snackbar_text: "",
      attendance_dialog: false,
      attendance_form_dialog: false,
      api: process.env.VUE_APP_API_URL.slice(0, -1),
      lessons: [],
      students: [],
      attendance: [],
      attendance_form: {},
      selected_lesson: null,
      selected_attendance: null,
      view_type: true,
      headers: [
        {
          text: "ФИО",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Телефонный номер", value: "phone" },
        { text: "Email", value: "email" },
      ],
    };
  },
  filters: {
    time_to_day(time) {
      return moment(time).format("D MMM");
    },
  },
  methods: {
    async LoadGroup() {
      let resp = await this.$dispatch(
        GROUP_GET,
        Number(this.$route.params.gid)
      );
      if (resp.success) {
        this.group = resp.result;
        let resp2 = await this.$dispatch(
          ATTENDANCE_GET,
          "?group=" + resp.result.id
        );
        if (resp2.success) {
          this.attendance = resp2.result;
        }
        let resp3 = await this.$dispatch(
          LESSONS_GET,
          "?group=" + resp.result.id
        );
        if (resp3.success) {
          this.lessons = resp3.result;
        }
      } else {
        alert(resp);
      }
      // Generating pdf report
      // var generateData = function (amount) {
      //   var result = [];
      //   var data = {
      //     coin: "100",
      //     game_group: "GameGroup",
      //     game_name: "XPTO2",
      //     game_version: "25",
      //     machine: "20485861",
      //     vlt: "0",
      //   };
      //   for (var i = 0; i < amount; i += 1) {
      //     data.id = (i + 1).toString();
      //     result.push(Object.assign({}, data));
      //   }
      //   return result;
      // };
      // function createHeaders(keys) {
      //   var result = [];
      //   for (var i = 0; i < keys.length; i += 1) {
      //     result.push({
      //       id: keys[i],
      //       name: keys[i],
      //       prompt: keys[i],
      //       width: 65,
      //       align: "center",
      //       padding: 0,
      //     });
      //   }
      //   return result;
      // }
      // var headers = createHeaders([
      //   "id",
      //   "coin",
      //   "game_group",
      //   "game_name",
      //   "game_version",
      //   "machine",
      //   "vlt",
      // ]);
      // var doc = new jsPDF({ putOnlyUsedFonts: true });
      // doc.table(1, 1, generateData(100), headers, { autoSize: true });
      // doc.save("a4.pdf");
    },
    async SaveAttendance() {
      let form = {
        lesson: this.selected_lesson.id,
        group: this.group.id,
      };
      for (let s in this.students) {
        form.student = this.students[s].id;
        if (this.students[s].came) {
          form.status = "came";
        } else {
          form.status = "absent";
        }
        await this.$dispatch(ATTENDANCE_POST, form);
        this.attendance_form_dialog = false;
        this.LoadGroup();
      }
    },
    RedirictGroups() {
      this.$router.push({ name: "Groups" });
    },
    isDone(lesson) {
      for (let a in this.attendance) {
        if (lesson.id == this.attendance[a].lesson.id) {
          return true;
        }
      }
      return false;
    },
    is_absent(lesson, student) {
      let student_attendance = [];
      for (let a in this.attendance) {
        if (student.id == this.attendance[a].student.id) {
          student_attendance.push(this.attendance[a]);
        }
      }
      for (let s in student_attendance) {
        if (
          lesson.id == student_attendance[s].lesson.id &&
          student_attendance[s].status === "came"
        ) {
          return true;
        }
      }
      return false;
    },
    DayColor(lesson) {
      if (this.isDone(lesson)) {
        return "green";
      }
      if (
        moment(lesson.start_time).format("YYY-MM-DD") ==
        moment().format("YYY-MM-DD")
      ) {
        return "yellow darken-2";
      }
      if (moment(lesson.start_time).isBefore(moment().add(1, "days"), "day")) {
        return "red";
      }
    },
    openDialog(lesson) {
      if (
        moment(lesson.start_time).format("YYYY-MM-DD HH:mm") ==
          moment()
            .add(1, "hours")
            .format("YYYY-MM-DD HH:" + moment(lesson.start_time).minute()) ||
        moment(lesson.start_time).isBefore()
      ) {
        let s = [];
        let students = [];
        for (let a in this.attendance) {
          if (lesson.id == this.attendance[a].lesson.id) {
            s.push(this.attendance[a]);
          }
        }
        if (s.length > 0) {
          this.selected_attendance = s;
          this.attendance_dialog = true;
        } else {
          for (let s in lesson.group.students) {
            students.push({
              id: lesson.group.students[s].id,
              first_name: lesson.group.students[s].user.first_name,
              last_name: lesson.group.students[s].user.last_name,
              came: false,
            });
          }
          this.students = students;
          this.selected_lesson = lesson;
          this.attendance_form_dialog = true;
        }
      } else {
        this.snackbar = true;
        this.snackbar_text =
          "Вы не можете проверить посещаемость до начала урока";
      }
    },
  },
  created() {
    this.LoadGroup();
  },
};
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";
.align-center {
  text-align: center;
}
.table {
  overflow: auto;
  width: 100%;
  display: block;
  white-space: nowrap;
  border-collapse: collapse;
  padding-top: 25px;
}
.absent {
  background: map-get($red, darken-1);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
}
.attended {
  background: map-get($green, lighten-1);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
}
thead {
  overflow: auto;
}
.name {
  padding: 5px;
}
table td {
  border: 1px solid silver;
  position: relative;
}
table tr {
  height: 25px;
}
.rotate {
  padding: 15px 0;
  transform: rotate(-70deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-70deg);
  /* Firefox */
  -moz-transform: rotate(-70deg);
  /* IE */
  -ms-transform: rotate(-70deg);
  /* Opera */
  -o-transform: rotate(-70deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
</style>